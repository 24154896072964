/* 스와이퍼 */
.swiper {
  width: 100%;
  position: relative;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 110px;
}

@media (min-width: 768px) {
  .swiper-slide img {
    margin-top: 66px;
  }
}

.swiper-pagination-bullet {
  background-color: #b3b3b3 !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background-color: #191919 !important;
}
