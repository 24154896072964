@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./css/base/_reset.css";

@import "./css/scrollbar.css";
@import "./css/input.css";
@import "./css/swiper.css";
@import "./css/animation.css";
@import "./css/terms.css";

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable-dynamic-subset.min.css");

body {
  min-width: 320px;
}

.app {
  background-color: skyblue;
}

/* 기록등록 탭버튼 */
.text-transition {
  transition: color 0.3s ease-in-out;
}

.shadow1 {
  -webkit-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
}

.pageContainer {
  @apply bg-pink-50 max-w-[360px] mx-auto h-dvh relative;
}

.formPage {
  @apply bg-white max-w-[360px] min-w-80 mx-auto h-screen flex flex-col items-center;

  .formPageTitle {
    @apply font-bold text-[24px];
  }

  .formPageForm {
    @apply w-full min-w-80  px-5;
  }

  .inputContainerDiv {
    @apply space-y-5 min-w-80;
  }

  .inputContainer {
    @apply w-full flex relative;
  }

  .formLabelRadio {
    @apply cursor-pointer py-2 px-3 border-solid border-[1px] border-gray1 text-gray1 rounded-lg;
  }

  .authenticationButtonDiv {
    @apply w-full flex flex-col min-w-80 font-bold items-center space-y-3;
    /* @apply w-full px-5 flex flex-col min-w-80 font-bold items-center space-y-3; */
  }

  .authenticationButton {
    @apply w-full font-semibold text-[16px] min-w-80 p-4 bg-main text-white rounded-xl;
  }

  .submitButton {
    @apply w-full min-w-80 h-[48px] flex justify-center items-center rounded-xl;
  }
}

.formLabel {
  @apply absolute w-[60px] min-w-[60px] h-[64px] flex items-center mr-9 text-gray2 text-p;
}

.errorText {
  @apply absolute top-[42px] text-darkRed text-sm;
}

.selectButton {
  @apply w-[70px] h-[30px] text-p border-[1px] rounded-lg border-gray1 text-gray1;
}

.selectedButton {
  @apply border-[1px] border-main text-main;
}

#addRecord {
  input {
    @apply placeholder:text-gray1 text-p text-gray4;
  }

  input::-webkit-datetime-edit {
    @apply text-p text-gray4;
  }
}
